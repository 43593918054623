<template>
  <div>
    <div v-if="componentType == 'read'">
      <v-card class="mb-4 pa-4">
        <v-row>
          <v-col cols="11">
            <div>
              {{ note.note }}
            </div>
          </v-col>
          <v-col class="text-right"
            ><v-icon class="mt-n7 mr-n2" @click="changeNoteInput('edit')" size="20">mdi-pencil</v-icon></v-col
          >
        </v-row>
        <v-row style="font-size: 12px">
          <v-col>Created At: {{ note.createdAt }}</v-col>
          <v-col>Created By: {{ note.firstName }} {{ note.lastName }}</v-col>
        </v-row>
      </v-card>
    </div>

    <div v-if="componentType == 'edit'" class="mb-4">
      <v-textarea
        :disabled="loading"
        solo
        v-model="note.note"
        class="mb-n4"
        label="Add lender note here."
      ></v-textarea>

      <v-btn v-if="!loading" dark @click="submitNote">Update Note</v-btn>
      <v-btn v-if="!loading" dark @click="changeNoteInput('read')" class="ml-4"
        >Cancel</v-btn
      >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: ["lenderId", "lenderContactId", "dealId", "noteType", "noteProp"],
  data() {
    return {
      loading: false,
      componentType: "read",
      note: this.noteProp,
    };
  },
  methods: {
    changeNoteInput(x) {
      this.componentType = x;
    },
    async submitNote() {
      try {
        this.loading = true;
        let authUser = this.$store.getters["Auth/getAuthUser"];

        this.note.updatedById = authUser.id;

        if (this.noteType == "lenderNote") {
          let insertedNote = await API().patch("/lenderNote", this.note);
     
          this.note = insertedNote.data;
          this.componentType = "read";
          this.loading = false;
        }

             if (this.noteType == "dealNote") {
          let insertedNote = await API().patch("/dealNote", this.note);
     
          this.note = insertedNote.data;
          this.componentType = "read";
          this.loading = false;
        }

        if (this.noteType == "lenderContactNote") {
          let insertedNote = await API().patch("/lenderContactNote", this.note);
     
          this.note = insertedNote.data;
          this.componentType = "read";
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeNote() {
      this.$emit("closeNote");
    },
  },
  mounted(){
    console.log(this.lenderContactId)
  }
};
</script>

<style>
</style>